import React from "react";
// import Footer from "./footer";
// import Header from "./header";

function Layout({ children }: any) {
  return (
    <div>
      {/* <Header /> */}
      {children}
      {/* <Footer /> */}
    </div>
  );
}

export default Layout;
