import NavButton from "./NavButton";
import React from "react";

const Score = (props: { score: number; quizzLength: number }) => {
  const { score, quizzLength } = props;

  return (
    <div className="text-center text-2xl text-purple-900 flex flex-col items-center">
      Ton score est de {score} / {quizzLength}
      {score >= (quizzLength * 3) / 4 && (
        <div className="mt-8 relative w-full h-56">
          <iframe
            title="taylor-swift-happy"
            src="https://giphy.com/embed/PFc4ohINrJWCY"
            className="absolute giphy-embed h-full w-full"
            allowFullScreen
          ></iframe>

        </div>
      )}
      {score < (quizzLength * 3) / 4 && score >= quizzLength / 2 && (
        <div className="mt-8 relative w-full h-48">
          <iframe
            title="taylor-swift-can-improve"
            src="https://giphy.com/embed/7SKWbnycqb2Pze62Zk"
            className="absolute giphy-embed h-full w-full "
            allowFullScreen
          ></iframe>

        </div>
      )}
      {score < quizzLength / 2 && (
        <div className="mt-8 relative w-full h-56">
          <iframe
            title="taylor-swift-sad"
            src="https://giphy.com/embed/xT4Ap3PcF1ENOwMAF2"
            className="absolute giphy-embed h-full w-full "
            allowFullScreen
          ></iframe>
        </div>
      )}
      <NavButton
        btnTxt="Recommencer"
        className="mt-4 bg-purple-600 hover-bg-purple-900"
        to="/"
      />
    </div>
  );
};

export default Score;
