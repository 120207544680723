const Guitar = (props: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 100 125"
      className={`enable-background:new ${props.className}`}
      xmlSpace="preserve"
    >
      <switch>
        <foreignObject
          requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
          x="0"
          y="0"
        />
        <g>
          <path d="M48.7,78.2c0.5-5.6,4.4-10.1,9.7-12c1.3-0.5,2.5-1.2,3.5-2.2c4.3-4.3,3.8-12-0.7-18.6l21.9-21.9l2.4-0.5    c0.6-0.1,1.2-0.5,1.7-0.9l1.1,1.1l0,0.9c0,0.1,0,0.1,0.1,0.2l0.9,0.9c0.1,0.1,0.3,0.1,0.4,0l1.6-1.6c0.1-0.1,0.1-0.3,0-0.4    l-0.9-0.9c-0.1-0.1-0.1-0.1-0.2-0.1l-0.9,0L88.4,21l1.8-1.8l1.1,1.1l0,0.9c0,0.1,0,0.1,0.1,0.2l0.9,0.9c0.1,0.1,0.3,0.1,0.4,0    l1.6-1.6c0.1-0.1,0.1-0.3,0-0.4l-0.9-0.9c-0.1-0.1-0.1-0.1-0.2-0.1l-0.9,0L91.4,18l1.8-1.8l1.1,1.1l0,0.9c0,0.1,0,0.1,0.1,0.2    l0.9,0.9c0.1,0.1,0.3,0.1,0.4,0l1.6-1.6c0.1-0.1,0.1-0.3,0-0.4l-0.9-0.9c-0.1-0.1-0.1-0.1-0.2-0.1l-0.9,0l-1.1-1.1l0.4-0.4    c0.7-0.7,0.7-1.8,0-2.5l-6.9-6.9c-0.7-0.7-1.8-0.7-2.5,0l-0.4,0.4l-1.1-1.1l0-0.9c0-0.1,0-0.1-0.1-0.2l-0.9-0.9    c-0.1-0.1-0.3-0.1-0.4,0l-1.6,1.6c-0.1,0.1-0.1,0.3,0,0.4l0.9,0.9c0.1,0.1,0.1,0.1,0.2,0.1l0.9,0l1.1,1.1L82,8.6l-1.1-1.1l0-0.9    c0-0.1,0-0.1-0.1-0.2l-0.9-0.9c-0.1-0.1-0.3-0.1-0.4,0l-1.6,1.6c-0.1,0.1-0.1,0.3,0,0.4l0.9,0.9c0.1,0.1,0.1,0.1,0.2,0.1l0.9,0    l1.1,1.1L79,11.6L78,10.5l0-0.9c0-0.1,0-0.1-0.1-0.2l-0.9-0.9c-0.1-0.1-0.3-0.1-0.4,0l-1.6,1.6c-0.1,0.1-0.1,0.3,0,0.4l0.9,0.9    c0.1,0.1,0.1,0.1,0.2,0.1l0.9,0l1.1,1.1c-0.4,0.5-0.7,1.1-0.9,1.7l-0.5,2.4L54.6,38.8c-6.6-4.5-14.3-5-18.6-0.7    c-1,1-1.7,2.2-2.2,3.5c-1.9,5.3-6.4,9.2-12,9.7c-6.1,0.5-11.7,2.8-15,6.1c-7.2,7.2-5,21.1,4.9,31c9.9,9.9,23.8,12.1,31,4.9    C45.9,90,48.2,84.3,48.7,78.2z M46.8,53.2c3,3,3,7.9,0,10.9c-3,3-7.9,3-10.9,0c-3-3-3-7.9,0-10.9C38.9,50.2,43.8,50.2,46.8,53.2z     M29.9,83.4L16.6,70.1c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0l13.3,13.3c0.8,0.8,0.8,2,0,2.8C31.9,84.2,30.7,84.2,29.9,83.4z" />
        </g>
      </switch>
    </svg>
  );
};
export default Guitar;
