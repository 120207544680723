import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import data from "../data/quizz.json";
import Question from "../components/Question";
import { randomizeArr } from "../utils/utils";
import Score from "../components/Score";
import ProgressBar from "../components/ProgressBar";
import { motion } from "framer-motion";
import { AuroraBackground } from "./../components/ui/aurora-background";

const quizzLength = 10;
const randomQuestions = randomizeArr(data).slice(0, quizzLength);

function Quizz() {
  const [score, setScore] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [nextQuestion, setNextQuestion] = useState(false);
  const [displayScore, setDisplayScore] = useState(false);
  const [rightAnsweredQuestions, setRightAnsweredQuestions] = useState<
    number[]
  >([]);

  useEffect(() => {
    if (nextQuestion) {
      if (questionIndex === randomQuestions.length - 1) {
        setDisplayScore(true);
      }
      setQuestionIndex(questionIndex + 1);
      setNextQuestion(false);
    }
  }, [nextQuestion]);

  return (
    <Layout>
      <AuroraBackground>
        <motion.div
          initial={{ opacity: 0.0, y: 0 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }}
          className="flex flex-col gap-4 items-center justify-center px-4"
        >
          <div className="absolute top-0 h-screen w-screen p-4 flex flex-col items-center">
            <h2 className="pt-12 pb-8 font-semibold md:text-4xl text-2xl text-center text-purple-900 w-1/2">
              Quizz
            </h2>
            <div className="md:w-2/3">
              <ProgressBar
                currentQuestionIndex={questionIndex}
                quizzLength={quizzLength}
                rightAnswers={rightAnsweredQuestions}
              />
              {!displayScore ? (
                <Question
                  data={randomQuestions[questionIndex]}
                  score={score}
                  setScore={(e: any) => setScore(e)}
                  setNextQuestion={(e: any) => setNextQuestion(e)}
                  setRightAnsweredQuestions={(e: any) =>
                    setRightAnsweredQuestions(e)
                  }
                  questionIndex={questionIndex}
                />
              ) : (
                <Score score={score} quizzLength={randomQuestions.length} />
              )}
            </div>
          </div>
        </motion.div>
      </AuroraBackground>
    </Layout>
  );
}

export default Quizz;
