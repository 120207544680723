import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export type QuestionType = {
  question: string;
  options: string[];
  rightAnswer: string;
};

const Question = (props: {
  data: QuestionType;
  score: number;
  setScore: Function;
  setNextQuestion: Function;
  setRightAnsweredQuestions: Function;
  questionIndex: number;
}) => {
  const {
    data,
    score,
    setScore,
    setNextQuestion,
    setRightAnsweredQuestions,
    questionIndex,
  } = props;
  const [selectedAnswer, setSelectedAnswer] = useState("");

  const [clickable, setClickable] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);

  useEffect(() => {
    if (selectedAnswer) {
      setClickable(true);
      setDisplayAlert(false);
    }
  }, [selectedAnswer]);

  const handleOnClick = () => {
    if (!selectedAnswer) {
      setDisplayAlert(true);
    } else {
      if (selectedAnswer === data.rightAnswer) {
        setScore(score + 1);
        setRightAnsweredQuestions((prev: number[]) => [...prev, questionIndex]);
      }
      setNextQuestion(true);
      setSelectedAnswer("");
      setClickable(false);
    }
  };
  return (
    <div className="py-2">
      <h2 className="font-semibold text-purple-900 pb-2">{data.question}</h2>
      <ul className="pl-4 mb-12 ">
        {data.options.map((option, index) => (
          <div
            onClick={() => setSelectedAnswer(option)}
            key={`${index}${option}`}
            className="py-1 text-fuschia-900"
          >
            <input
              type="radio"
              id={option}
              name="option"
              value={option}
              className="w-4 h-4 bg-gray-100 border-gray-300  dark:bg-gray-700 dark:border-gray-600 accent-purple-900"
            />
            <label htmlFor={option} className="px-2">
              {option}
            </label>
          </div>
        ))}
      </ul>
      <div className="gap-3 flex justify-center ">
        <Link
          className={`py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent transition ease-in-out delay-150 bg-purple-600 hover:-translate-y-1 hover:scale-110 duration-300  ${
            clickable ? " hover:bg-purple-900" : "hover:bg-red-400"
          }  text-white disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
          to="/quizz"
          onClick={() => handleOnClick()}
        >
          Question suivante
          <svg
            className="flex-shrink-0 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m9 18 6-6-6-6" />
          </svg>
        </Link>
      </div>
      {displayAlert && (
        <p className="text-red-400 font-semibold text-center text-sm">
          Veuillez choisir une réponse
        </p>
      )}
    </div>
  );
};

export default Question;
