import Guitar from "../assets/Guitar";

const ProgressBar = (props: {
  currentQuestionIndex: number;
  quizzLength: number;
  rightAnswers: number[];
}) => {
  const { currentQuestionIndex, quizzLength, rightAnswers } = props;
  return (
    <div className="flex pb-8 justify-center ">
      {quizzLength > 0 &&
        Array.from({ length: quizzLength }, (_, i) => i).map((el, index) => (
          <Guitar
            key={index}
            className={`md:w-12 md:h-12 w-7 h-7 ${
              index < currentQuestionIndex &&
              (rightAnswers.includes(index)
                ? "fill-purple-900"
                : "fill-red-400")
            } ${
              index === currentQuestionIndex && "animate-bounce fill-purple-400"
            } ${
              index > currentQuestionIndex && "fill-purple-400 animate-pulse"
            }`}
          />
        ))}
    </div>
  );
};

export default ProgressBar;
